import { ConversionMap, required, optional } from "@lib/types/import"
import { Company, Addresses, Address, Contact, Insurance, Franchise, MaximumSalary, Scale, ScaleType,
	SurvivorsPension, SurvivorsPensionType, Agreement, InvestmentFreedom, Intermediary, DataDeliveryChannel,
	Permission, YearsOfServiceDetermination, EmployeeContribution, EmployeeContributionType, PremiumFrequency, AnwGap, CoverageDefault,
	CoverageOption, ProductFrameworkType } from "./types"
import { alphanumeric, bool, enumConstant, numeric, date } from "@lib/import/convert"
import { one, many, def } from "@lib/import/extract"
import { EnumOption } from "store/types"

const address: ConversionMap<Address> = {
	street: ["street", alphanumeric, optional],
	number: ["number", alphanumeric, optional],
	letter: ["letter", alphanumeric, optional],
	suffix: ["suffix", alphanumeric, optional],
	postalCode: ["postalCode", alphanumeric, optional],
	city: ["city", alphanumeric, optional]
}

const addresses: ConversionMap<Addresses> = {
	business: ["business", one(address), optional],
	postal: ["postal", one(address), optional]
}

const contact: ConversionMap<Contact> = {
	name: ["name", alphanumeric, optional],
	email: ["email", alphanumeric, optional],
	phoneNumber: ["phoneNumber", alphanumeric, optional]
}

const coverageDefault: ConversionMap<CoverageDefault> = {
	amount: ["amount", numeric, optional],
	percentage: ["percentage", numeric, required]
}

const coverageOption: ConversionMap<CoverageOption> = {
	active: ["active", bool, optional],
	percentage: ["percentage", numeric, optional],
	amount: ["amount", numeric, optional]
}

const anwGap: ConversionMap<AnwGap> = {
	active: ["active", bool, required],
	coverageOptions: ["coverageOptions", many(coverageOption), optional],
	coverageDefault: ["coverageDefault", one(coverageDefault), optional],
	defaultInsured: ["defaultInsured", def(false, bool), optional],
	hasEmployeeContribution: ["hasEmployeeContribution", bool, optional],
	indexation: ["indexation", numeric, optional]
}

const insurance: ConversionMap<Insurance> = {
	anwGap: ["anwGap", one(anwGap), required],
	premiumWaiver: ["premiumWaiver", bool, required],
	extraSavings: ["extraSavings", bool, required]
}

const franchise: ConversionMap<EnumOption<Franchise, number>> = {
	constant: ["constant", enumConstant(Franchise), required],
	value: ["value", numeric, optional]
}

const maximumSalary: ConversionMap<EnumOption<MaximumSalary, number>> = {
	constant: ["constant", enumConstant(MaximumSalary), required],
	value: ["value", numeric, optional]
}

const scale: ConversionMap<Scale> = {
	type: ["type", enumConstant(ScaleType), required],
	percentage: ["percentage", numeric, optional],
	utilizationRate: ["utilizationRate", numeric, optional],
	fixedBasePremium: ["fixedBasePremium", numeric, optional]
}

const survivorsPension: ConversionMap<SurvivorsPension> = {
	type: ["type", enumConstant(SurvivorsPensionType), required],
	partnerAccrual: ["partnerAccrual", numeric, optional],
	orphanAccrual: ["orphanAccrual", numeric, optional],
	indexation: ["indexation", numeric, optional],
	yearsOfServiceDetermination: ["yearsOfServiceDetermination", enumConstant(YearsOfServiceDetermination), optional]
}

const employeeContribution: ConversionMap<EmployeeContribution> = {
	percentage: ["percentage", numeric, required],
	type: ["type", enumConstant(EmployeeContributionType), required]
}

const permission: ConversionMap<Permission> = {
	viewParticipants: ["viewParticipants", bool, optional],
	administrationForEmployer: ["administrationForEmployer", bool, optional]
}

const intermediary: ConversionMap<Intermediary> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, optional],
	contact: ["contact", one(contact), optional],
	address: ["address", one(addresses), optional],
	license: ["license", alphanumeric, optional],
	permission: ["permission", one(permission), optional]
}

const agreement: ConversionMap<Agreement> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, required],
	startDate: ["startDate", date, required],
	endDate: ["endDate", date, optional],
	originalStartDate: ["originalStartDate", date, required],
	enrollmentAge: ["enrollmentAge", numeric, required],
	pensionAge: ["pensionAge", numeric, required],
	packageCharacteristic: ["packageCharacteristic", alphanumeric, required],
	packageVariant: ["packageVariant", alphanumeric, required],
	insurance: ["insurance", one(insurance), required],
	franchise: ["franchise", one(franchise), required],
	maximumSalary: ["maximumSalary", one(maximumSalary), required],
	scale: ["scale", one(scale), required],
	survivorsPension: ["survivorsPension", one(survivorsPension), required],
	employeeContribution: ["employeeContribution", one(employeeContribution), required],
	investmentFreedom: ["investmentFreedom", enumConstant(InvestmentFreedom), required],
	intermediary: ["intermediary", one(intermediary), optional],
	dataDeliveryChannel: ["dataDeliveryChannel", enumConstant(DataDeliveryChannel), optional],
	premiumFrequency: ["premiumFrequency", enumConstant(PremiumFrequency), optional],
	newPoliciesAllowed: ["newPoliciesAllowed", bool, optional],
	productType: ["productType", enumConstant(ProductFrameworkType), optional]
}

const company: ConversionMap<Company> = {
	externalIdentifier: ["externalIdentifier", alphanumeric, required],
	name: ["name", alphanumeric, optional],
	address: ["address", one(addresses), optional],
	contact: ["contact", one(contact), optional],
	chamberOfCommerceNumber: ["chamberOfCommerceNumber", alphanumeric, optional],
	payrollTaxNumber: ["payrollTaxNumber", alphanumeric, optional],
	agreements: ["agreements", many(agreement), required]
}

export const convertCompany = one(company)
export const convertIntermediary = one(intermediary)
export const convertContactDetails = one(contact)
