
	import Vue from "vue"
	import Component from "vue-class-component"

	import TabContainer from "@components/container/TabContainer.vue"
	import TabButton from "@components/button/TabButton.vue"
	import DataView from "@components/data/view/DataView.vue"
	import PButton from "@portal-components/ui/input/PButton.vue"
	import PDateInput from "@portal-components/ui/input/PDateInput.vue"
	import Loader from "custom/Loader.vue"
	import { Column } from "@components/data/view/types"
	import { participantModule } from "store/modules"
	import { StoreAction } from "@lib/types/vuex"

	import toaster from "@portal-components/ui/utils/toaster"

	import Grid from "custom/table/Grid.vue"

	import { OneOffExtraSavingsRequest, PeriodicExtraSavingsRequest, SavingsRequestType } from "api/models/participants"

	import { isNull, camelCase } from "lodash-es"

	enum EmployeeChoicesTabs {
		ONEOFF = "ONEOFF",
		PERIODIC = "PERIODIC"
	}

	enum PeriodicChoicesRequestView {
		OVERVIEW = "OVERVIEW",
		CONFIRM_REQUEST = "CONFIRM_REQUEST"
	}

	@Component({
		components: {
			TabContainer,
			TabButton,
			DataView,
			Grid,
			PButton,
			PDateInput,
			Loader
		}
	})
	export default class EmployeeChoices extends Vue {
		@participantModule.Action getOneOffExtraSavings!: StoreAction<Array<OneOffExtraSavingsRequest>>
		@participantModule.Action getPeriodicExtraSavings!: StoreAction<Array<PeriodicExtraSavingsRequest>>
		@participantModule.Action confirmPeriodicExtraSavings!: StoreAction<PeriodicExtraSavingsRequest>
		@participantModule.Getter oneOffExtraSavings!: Array<OneOffExtraSavingsRequest>
		@participantModule.Getter periodicExtraSavings!: Array<PeriodicExtraSavingsRequest>

		readonly baseTranslationKey = "employees.choices"
		readonly SavingsRequestType = SavingsRequestType
		readonly PeriodicChoicesRequestView = PeriodicChoicesRequestView
		readonly EmployeeChoicesTabs = EmployeeChoicesTabs
		readonly isNull = isNull
		readonly camelCase = camelCase

		readonly columnsOneOff: ReadonlyArray<Column<OneOffExtraSavingsRequest>> = [
			{ title: `${ this.baseTranslationKey }.column.eventDate`, field: "eventDate" },
			{ title: `${ this.baseTranslationKey }.column.policyId`, field: "policyId" },
			{ title: `${ this.baseTranslationKey }.column.name`, field: "name" },
			{ title: `${ this.baseTranslationKey }.column.amountOneOff`, field: "amount" }
		]

		readonly columnsPeriodic: ReadonlyArray<Column<PeriodicExtraSavingsRequest>> = [
			{ title: `${ this.baseTranslationKey }.column.eventDate`, field: "eventDate", class: "col-periodic-event-date-time" },
			{ title: `${ this.baseTranslationKey }.column.type`, field: "type", class: "col-periodic-type" },
			{ title: `${ this.baseTranslationKey }.column.policyId`, field: "policyId", class: "col-periodic-policyId" },
			{ title: `${ this.baseTranslationKey }.column.name`, field: "name", class: "col-periodic-name" },
			{ title: `${ this.baseTranslationKey }.column.amountPeriodic`, field: "amount", class: "col-periodic-amount" }
		]

		tab: EmployeeChoicesTabs = EmployeeChoicesTabs.ONEOFF
		currentPeriodicChoicesRequestView: PeriodicChoicesRequestView = PeriodicChoicesRequestView.OVERVIEW

		periodicSavingsRequest: PeriodicExtraSavingsRequest | null = null
		loadingOneOffSavings = false
		loadingPeriodicSavings = false
		savingRequest = false
		confirmRequest = false
		savingError = false

		async mounted(): Promise<void> {
			this.loadingOneOffSavings = true
			await this.getOneOffExtraSavings()
			this.loadingOneOffSavings = false
			await this.loadPeriodicExtraSavings()
		}

		async loadPeriodicExtraSavings(): Promise<void> {
			this.loadingPeriodicSavings = true
			await this.getPeriodicExtraSavings()
			this.loadingPeriodicSavings = false
		}

		handleEmployeeChanges(): void {
			this.$emit("handleEmployeeChanges")
		}

		async sendConfirmation(): Promise<void> {
			this.savingRequest = true
			this.savingError = false

			if (!this.periodicSavingsRequest) {
				return
			}

			try {
				this.confirmRequest = true
				await this.confirmPeriodicExtraSavings(this.periodicSavingsRequest)
			} catch (error) {
				this.savingError = true
				toaster.error({ title: "general.error" })
			} finally {
				this.savingRequest = false
			}
		}
	}
