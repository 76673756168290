import actions from "./actions"
import getters from "./getters"
import { Company } from "./types"
import { Module } from "vuex"
import { RootState } from "store/index"

const userModule: Module<Company, RootState> = {
	state : {
		externalIdentifier: undefined,
		address: {
			business: {
				city: undefined,
				number: undefined,
				letter: undefined,
				suffix: undefined,
				postalCode: "",
				street: ""
			},
			postal: {
				city: undefined,
				number: undefined,
				letter: undefined,
				suffix: undefined,
				postalCode: undefined,
				street: undefined
			}
		},
		contact: {
			email: undefined,
			name: undefined,
			phoneNumber: undefined
		},
		agreements: []
	},
	actions,
	getters,
	mutations: {
		hydrate(state, data: Company): void {
			Object.assign(state, data)
		}
	},
	namespaced: true
}

export default userModule
