
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Inject, Prop, Emit } from "vue-property-decorator"

	import Card from "@components/panel/Card.vue"
	import { View, SortOrder } from "@components/data/view/types"
	import { ColValue, Row } from "./model/row"
	import { extractValue } from "./utils/utils"
	import { GridColumn } from "./model/grid-column"

	@Component({
		components: {
			Card
		}
	})
	export default class Grid<T> extends Vue {
		@Prop({ type: String, required: true }) idField!: string
		@Prop({ type: Array, required: true }) columns!: Array<GridColumn>
		@Prop({ type: Boolean, default: false }) sortable!: boolean
		@Prop({ type: String, default: null }) defaultSortField!: string | null
		@Prop({ type: Number, default: null }) defaultSortOrder!: SortOrder | null

		@Inject() view!: View<T>

		private sortField: string | null = this.defaultSortField ?? null
		private sortOrder: SortOrder | null = this.defaultSortOrder ?? null

		mounted(): void {
			if (!this.sortField || !this.sortOrder) { return }
			this.view.sort(this.sortField as keyof T, this.sortOrder)
		}

		get rows(): ReadonlyArray<Record<string, any>> {
			return this.view.data.range
		}

		canShowTooltip(col: GridColumn, rowData: Row): boolean {
			if (col.tooltip instanceof Function) {
				return col.tooltip(rowData)
			}

			return !!col.tooltip
		}

		extractColValue(rowData: Row, key: string): ColValue {
			return extractValue(rowData, key)
		}

		sort(field: string): void {
			if (!this.sortable) {
				return
			}

			if (this.sortField && this.sortOrder) {
				if (field === this.sortField) {
					this.sortOrder *= -1
				} else {
					this.view.sort(this.sortField as keyof T, 0)
					this.sortOrder = 0
				}
			}
			this.sortField = field
			this.sortOrder = this.sortOrder || 1
			this.view.sort(this.sortField as keyof T, this.sortOrder)
		}

		sortClass(field: string): string | undefined {
			if (!this.sortable) {
				return ""
			}

			const sortClass = "sort"

			if (this.sortField === field && this.sortOrder !== null) {
				return `${ sortClass } ${ this.sortOrder === 1 ? "sort--up" : "sort--down" }`
			}
			return sortClass
		}

		isLast(items: Array<any>, index: number): boolean {
			return index === (items.length - 1)
		}

		@Emit("row:clicked")
		rowClicked(row: Row): Row {
			return row
		}
	}
